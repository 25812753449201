import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

    :root {
        --primary: #ff6633;
        --secondary: #339fff;
        --white: #ffffff;
        --slate: #22242d;

        --primary-grad: linear-gradient(
          45deg,
          hsl(21deg 100% 62%) 0%,
          hsl(26deg 100% 61%) 11%,
          hsl(30deg 100% 60%) 22%,
          hsl(34deg 100% 59%) 33%,
          hsl(38deg 100% 58%) 44%,
          hsl(42deg 100% 57%) 56%,
          hsl(45deg 100% 56%) 67%,
          hsl(49deg 100% 56%) 78%,
          hsl(52deg 100% 56%) 89%,
          hsl(55deg 100% 57%) 100%
        );

        --secondary-grad:linear-gradient(
          45deg,
          hsl(205deg 97% 41%) 0%,
          hsl(203deg 100% 42%) 11%,
          hsl(202deg 100% 43%) 22%,
          hsl(201deg 95% 45%) 33%,
          hsl(201deg 86% 48%) 44%,
          hsl(200deg 84% 52%) 56%,
          hsl(200deg 87% 55%) 67%,
          hsl(199deg 90% 58%) 78%,
          hsl(199deg 94% 61%) 89%,
          hsl(198deg 98% 64%) 100%
        );

        --bg-dark: rgb(17, 18, 28);
        --bg-light: rgb(12, 20, 39);
    }

    html {
        font-size: 10px;
        background-image: linear-gradient(to bottom, var(--bg-dark), var(--bg-light));
        background-attachment: fixed;
    }

    body {
        font-size: 1.7rem;
        height: 100%;
    }

    fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }


  button {
    background: var(--primary);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--shadow);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  }

  img {
    max-width: 100%;
    box-shadow: 0 0 20px -10px rgb(0,0,0,0.4);
    min-width: 50px;
  }

  .pixelated-load[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

    /* Scrollbar Styles */
    body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
  }

  a {
    text-decoration: none;
  }

  blockquote {
    background-image: var(--secondary-grad);
    padding:3rem;
    border-radius: 1rem;
    line-height: 3rem;

  }

  .button {
    font-family: var(--title-font);
    font-size: 1.5rem;
    display: flex;
    gap: 0.4rem;
    align-items: center;
    padding: 1.5rem;
    color: black;
    border-radius: 10px;
    h3 {
      margin: auto;
    }
    &:hover {
      background: lightgrey;
    }
    &[aria-current='page'] {
      background: lightgrey;
    }
    @media (max-width: 800px) {
      font-size: 2rem;
    }
  }

  hr {
    border: 0;
    height: 8px;
    background-size: 1500px;
  }
  .hero-img {
    max-height: 500px;
    /* position: absolute; */
    margin:auto;
    margin-bottom: 5rem;
    display: block;

    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;

    @media (max-width: 1250px) {
      width: 95%;
      margin-bottom: 3rem;
    }
  }
  .content {
    margin: 0 auto;
    max-width: 1200px;
    margin: 2rem;


    @media (max-width: 1200px) {
      margin: 0 3rem;
    }
  }

  .block p, .block blockquote, .block div, .block h2, .title{
    margin-bottom:4rem;
    margin-top:0;
  }

  .rounded {
    border-radius: 2rem;
  }
  .shadow {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  }
`;

export default GlobalStyles;
