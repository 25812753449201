import { createGlobalStyle } from 'styled-components';

import NunitoBold from '../assets/fonts/Nunito-Bold.ttf';
import NunitoRegular from '../assets/fonts/Nunito-Regular.ttf';
// import PfefferMediaeval from '../assets/fonts/PfefferMediaeval.otf';

const Typography = createGlobalStyle`
:root {
    --header-font: 'Nunito-Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --logo-nav-font: 'Nunito-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* --header-font-alt: 'PfefferMediaeval', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  }
  @font-face {
    font-family: 'Nunito-Bold';
    src: url(${NunitoBold});
  }
  @font-face {
    font-family: 'Nunito-Regular';
    src: url(${NunitoRegular});
  }
  /* @font-face {
    font-family: 'PfefferMediaeval';
    src: url(${PfefferMediaeval});
  } */
  html {
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-family: var(--logo-nav-font);
    color: var(--white);
  }
  p, li {
    line-height: 3rem;
    font-size:18px;
    /* letter-spacing: 1px; */
  }
  h1,h2,h3,h4,h5,h6{
    font-family: var(--header-font);
    font-weight: 700;
    margin: 0;
    margin-bottom:2rem;
    text-align: left;
    color: white;
  }
  h1 {
    text-align:center;
    font-family: var(--header-font);
  }

  .logo a, .nav li a{
    font-family: var(--logo-nav-font);
    font-weight: 700;
    margin: 0;
    background:none;
    background-clip: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
  }
  li a, p a {
    text-decoration: none;
    color:#FFD063;
    background: linear-gradient(transparent 60%, var(--primary)70% 10px);
    background-image: var(--primary-grad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }

  p a :hover {
    text-decoration: underline;
  }
  
  .center {
    text-align: center;
  }
  .sans-serif {
    font-family: var(--logo-nav-font);
  }
  .serif {
    font-family: Georgia, 'Times New Roman', Times, serif;
  }


  blockquote {
    font-family: var(--logo-nav-font);
  }
  .notice-container
  {
    font-family: var(--header-font);
    font-size:1.7rem;
  }
`;

export default Typography;
