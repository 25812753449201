import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const LogoStyles = styled.div`
  font-size: 2rem;
`;

export default function Logo() {
  return (
    <LogoStyles className="logo">
      <Link to="/">Nick Brown</Link>
    </LogoStyles>
  );
}
