import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Logo from './Logo';

const ContentStyles = styled.nav`
  padding-bottom: 23rem;
  position: relative;
`;

export default function Nav({ children }) {
  return <ContentStyles>{children}</ContentStyles>;
}
