import React from 'react';
import 'normalize.css';
import styled from 'styled-components';
import Nav from './Nav';
import Content from './Content';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const ContentStyles = styled.div`
  max-width: 1200px;
  margin: auto;
  position: relative;
  min-height: 98vh;
`;

export default function Layout({ children }) {
  return (
    <>
      <Typography />
      <GlobalStyles />

      <ContentStyles>
        <Nav />
        <Content>{children}</Content>

        <Footer />
      </ContentStyles>
    </>
  );
}
