import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Logo from './Logo';

const NavStyles = styled.nav`
  margin: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  ul {
    margin: 2rem;
    padding: 0;
    text-align: center;
    list-style: none;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* grid-column-start: span 1; */
    grid-gap: 1rem;
    justify-items: right;
  }

  li {
    order: 1;
  }

  .logo a {
    display: inline-block;
  }

  a {
    color: var(--white);
    padding: 1.5rem;
    text-align: center;
    vertical-align: middle;
    margin: auto;

    border-radius: 1rem;

    &:hover {
      background-color: var(--slate);
    }
  }

  @media (max-width: 400px) {
    margin: 2rem 0;

    ul {
      margin: 0;
      grid-gap: 0rem;
    }
    .logo a {
      /* text-align: left; */
    }
  }
`;

export default function Nav() {
  return (
    <NavStyles>
      <Logo />
      <ul className="nav">
        <li>
          <Link to="/projects">Projects</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
      </ul>
    </NavStyles>
  );
}
